<div class="survey-banner">
  Share your MyKingston feedback on
  <a
    rel="noopener"
    target="_blank"
    href="https://getinvolved.cityofkingston.ca/mykingston-user-testing/surveys/user-experience-survey"
    >Get Involved Kingston</a
  >
  for a chance to win $100 gift card!
</div>
