<div *ngIf="currentWindow !== currentWindow.parent && production; else notIframe">
  <p>MyKingston cannot be loaded in an iframe</p>
</div>
<ng-template #notIframe>
  <div class="app" [ngClass]="isDarkMode ? 'theme-dark' : ''">
    <header>
      <app-announcement-banner></app-announcement-banner>
      <app-activity-detection *ngIf="loggedIn$ | async"></app-activity-detection>
      <app-header (darkModeSwitched)="switchMode($event)" (menuToggle)="onMenuToggle()" [mode]="sideNavMode">
      </app-header>
    </header>
    <main>
      <!-- <button (click)="hideSideNavInitial = !hideSideNavInitial">HIDE</button> -->
      <app-content-shell
        [mode]="sideNavMode"
        [sideNavToggle]="sideNavOpen"
        [hideSideNav]="hideSideNavInitial"
        [loggedIn]="loggedIn$ | async"
        (sideNavClosed)="onSideNavClosed()"
        ><router-outlet />
      </app-content-shell>
    </main>
    <footer><app-footer /></footer>
  </div>
</ng-template>
